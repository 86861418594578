import { FetchOptions, fetchAPI } from '.';
import { timeUtils, utils } from '../helpers';
import { DiscountUID } from '../pages/discounts/manage/disc.manager.types';
import {
	Discount,
	DiscountGroup,
	DiscountPriorityInsert,
	DiscountRedemption,
	DiscountsContext,
	EntryMap,
	CodeUploadRequest,
	CodeGenRequest,
	CouponCodeBatch,
} from '@/legacy-types';

function getDiscounts(options?: FetchOptions): Promise<DiscountUID[]> {
	return fetchAPI(`/discount/:uid`, { method: 'GET', ...options });
}

function getDiscount(id?: string, options?: FetchOptions): Promise<Discount> {
	return fetchAPI(`/discount/:uid/${id}`, { method: 'GET', ...options });
}

function createDiscount(discount: Discount, fetchOptions?: FetchOptions): Promise<Discount> {
	return fetchAPI(`/discount/:uid`, { method: 'POST', payload: discount, ...fetchOptions });
}

function updateDiscount(discount: Discount, fetchOptions?: FetchOptions): Promise<Discount> {
	return fetchAPI(`/discount/:uid/${discount.id}`, { method: 'PUT', payload: discount, ...fetchOptions });
}

function archiveDiscount(id?: string, status?: boolean): Promise<void> {
	return fetchAPI(`/discount/:uid/${id}/${status}`, { method: 'PUT' });
}

function getPublicDiscount(id?: string, key?: string, options?: FetchOptions): Promise<Discount> {
	return fetchAPI(`/discount/public/:uid/${id}/${key}`, { method: 'GET', ...options });
}

function getAllDiscountRedemptions(options?: FetchOptions): Promise<{ [id: string]: DiscountRedemption[] }> {
	return fetchAPI(`/discountRedemptions/:uid`, { method: 'GET', ...options });
}

function getDiscountRedemptions(id?: string): Promise<DiscountRedemption[]> {
	return fetchAPI(`/discountRedemptions/:uid/${id}`, { method: 'GET' });
}

function setDiscountOrderPriority(payload?: DiscountPriorityInsert[]): Promise<void> {
	return fetchAPI(`/discount/orderPriorities/:uid`, { method: 'POST', payload });
}

function getDiscountGroups(options?: FetchOptions): Promise<DiscountGroup[]> {
	return fetchAPI(`/discount/groups/:uid`, { method: 'GET', ...options });
}

function getDiscountGroup(id?: string): Promise<DiscountGroup> {
	return fetchAPI(`/discount/group/:uid/${id}`, { method: 'GET' });
}

function createDiscountGroup(group: DiscountGroup): Promise<DiscountGroup> {
	return fetchAPI(`/discount/group/:uid`, { method: 'POST', payload: group });
}

function updateDiscountGroup(group: DiscountGroup): Promise<DiscountGroup> {
	return fetchAPI(`/discount/group/:uid/${group.id}`, { method: 'PUT', payload: group });
}

function archiveDiscountGroup(id?: string, status?: boolean): Promise<void> {
	return fetchAPI(`/discount/group/:uid/${id}/${status}`, { method: 'PUT' });
}

async function getDiscountsMemoized(...[discounts, setDiscounts]: DiscountsContext): Promise<Discount[]> {
	const isSet = discounts && Object.keys(discounts.discounts || {}).length > 0;
	const needsUpdate = new Date().getTime() - (discounts?.updated || 0) > timeUtils.units.MINUTE_MS * 10; // Templates are updated every 10 minutes or per refresh
	// Check that number of entities matches the number of templates
	// We have already fetched the data, and it's not too old
	if (isSet && !needsUpdate) return Object.values(discounts.discounts || {});
	// We haven't fetched the data yet, or it's too old
	const discountsList = (await getDiscounts()) || [];
	const discountsMap = discountsList.reduce((acc, discount) => ({ ...acc, [discount.id || '']: discount }), {}) as EntryMap<Discount>;
	setDiscounts({ discounts: discountsMap, updated: new Date().getTime() });
	return discountsList;
}

function getDiscountCodesBatches(id?: string): Promise<CouponCodeBatch[]> {
	return fetchAPI(`/:uid/discount/${id}/batch`, { method: 'GET', forceToken: utils.TOKEN });
}

function getDiscountCodesBatch(id?: string, batchID?: string): Promise<CouponCodeBatch> {
	return fetchAPI(`/:uid/discount/${id}/batch/${batchID}`, { method: 'GET', forceToken: utils.TOKEN });
}

function setDiscountCodesBatchStatus(id?: string, batchID?: string, status?: boolean): Promise<void> {
	return fetchAPI(`/:uid/discount/${id}/batch/${batchID}/${status}`, { method: 'PUT', forceToken: utils.TOKEN });
}

function uploadDiscountCodesBatch(id?: string, payload?: CodeUploadRequest): Promise<CouponCodeBatch> {
	return fetchAPI(`/:uid/discount/${id}/batch/upload`, { method: 'POST', payload, forceToken: utils.TOKEN });
}

function generateDiscountCodesBatch(id?: string, payload?: CodeGenRequest): Promise<CouponCodeBatch> {
	return fetchAPI(`/:uid/discount/${id}/batch/generate`, { method: 'POST', payload, forceToken: utils.TOKEN });
}

export const DiscountsAPI = {
	getDiscounts,
	getDiscount,
	createDiscount,
	updateDiscount,
	archiveDiscount,
	getPublicDiscount,
	getAllDiscountRedemptions,
	getDiscountRedemptions,
	setDiscountOrderPriority,
	getDiscountGroups,
	getDiscountGroup,
	createDiscountGroup,
	updateDiscountGroup,
	archiveDiscountGroup,
	getDiscountsMemoized,
	getDiscountCodesBatches,
	getDiscountCodesBatch,
	setDiscountCodesBatchStatus,
	uploadDiscountCodesBatch,
	generateDiscountCodesBatch,
};
