import { UserSettings } from '@alpine-iq/shared';
import { fetchAPI, FetchOptions } from '.';

export function getUserSettings(storeID?: string, options?: FetchOptions): Promise<Partial<UserSettings>> {
	return fetchAPI(`/user/settings/:uid${storeID !== undefined ? `?storeID=${storeID}` : ''}`, { ...options });
}

export function updateUserSettings(settings: Partial<UserSettings>, storeID?: string, options?: FetchOptions): Promise<Partial<UserSettings>> {
	return fetchAPI(`/user/settings/:uid${storeID !== undefined ? `?storeID=${storeID}` : ''}`, { method: 'PUT', payload: settings, ...options });
}

export function deleteUserSettings(storeID?: string, options?: FetchOptions): Promise<void> {
	return fetchAPI(`/user/settings/:uid${storeID !== undefined ? `?storeID=${storeID}` : ''}`, { method: 'DELETE', ...options });
}
