import { FetchOptions, fetchAPI } from '.';
import { UserAlert } from '@/legacy-types';

export interface UserAlertQuery {
	uid?: string;
	email?: string;
	role?: string;
	active?: boolean;
	showAll?: boolean;
	showHistorical?: boolean;
	showArchived?: boolean;
	manuallyCreated?: boolean;
	nonManuallyCreated?: boolean;
	admin?: boolean;
	reQueue?: boolean;
	showAcknowledged?: boolean;
}

function getUserAlerts(config: UserAlertQuery, options?: FetchOptions): Promise<UserAlert[]> {
	const query: string[] = Object.keys(config || {}).map((key: string) => `${key}=${config[key as keyof UserAlertQuery]}`);
	return fetchAPI(`/userAlerts/:uid?${query.join('&')}`, { method: 'GET', ...options });
}

function getUserAlert(id?: string): Promise<UserAlert> {
	return fetchAPI(`/userAlerts/:uid/${id}`, { method: 'GET' });
}

function createUserAlert(alert: UserAlert): Promise<UserAlert> {
	return fetchAPI(`/userAlerts/:uid`, { method: 'POST', payload: alert });
}

function updateUserAlert(alert: UserAlert): Promise<UserAlert> {
	return fetchAPI(`/userAlerts/:uid/${alert.id}`, { method: 'PUT', payload: alert });
}

function setUserAlertStatus(id?: string, active?: boolean, status?: boolean): Promise<void> {
	return fetchAPI(`/userAlerts/status/:uid/${id}?type=${active ? 'active' : 'archived'}&status=${status}`, { method: 'PUT' });
}

function deleteUserAlert(id?: string): Promise<void> {
	return fetchAPI(`/userAlerts/:uid/${id}`, { method: 'DELETE' });
}

export type UAClickType = 'link' | 'prefix' | 'text' | 'suffix';
export type UAActionType = 'acknowledge' | 'snooze' | 'view';

export interface UserAlertUpdateActionInput {
	uid?: string;
	email?: string;

	action?: UAActionType;
	clickType?: UAClickType;

	snoozeDuration?: number;

	ids: string[];
	admin?: boolean;
}

export type UserAlertUpdateActionResponse = {
	alerts: UserAlert[];
	errors: string[];
};

function updateUserAlertsClicked(payload: UserAlertUpdateActionInput): Promise<UserAlertUpdateActionResponse> {
	return fetchAPI(`/userAlerts/action/:uid`, { method: 'POST', payload });
}

export const UserAlertsAPI = {
	getUserAlerts,
	getUserAlert,
	createUserAlert,
	updateUserAlert,
	setUserAlertStatus,
	deleteUserAlert,
	updateUserAlertsClicked,
};
