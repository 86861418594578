import { FetchOptions, fetchAPI } from '.';
import { MediaFileMetadata, MediaUploaderOptions } from '../components/deprecated/legacy-media-library/media.helpers';
import { EntryMap } from '@/legacy-types';

type UploadInput = {
	files: File[] | File;
	fileMetadata: EntryMap<MediaFileMetadata>;
	config: MediaUploaderOptions;
};

function uploadUserMedia(input: UploadInput, options?: FetchOptions): Promise<MediaFileMetadata[]> {
	let fileList: File[] = input.files as File[];
	if (input.files instanceof File) {
		fileList = [input.files];
	}

	if (!fileList?.length) throw new Error('No files to upload');

	const payload = new FormData();

	for (const f of fileList) {
		payload.append('file', f, f.name.replace(/[% +]+/g, '_'));

		// Set metadata per file so it can be accessed on the server per file
		const metadata = input.fileMetadata[f.name] || {};
		// Set source if not already set
		if (input.config.source && !metadata.source) {
			metadata.source = input.config.source;
		}

		payload.append(`meta:::${f.name}`.replace(/[% +]+/g, '_'), JSON.stringify(metadata));
	}

	return fetchAPI(`/media/:uid`, { method: 'POST', payload, ...options });
}

export const MediaAPI = {
	uploadUserMedia,
};
