import { fetchAPI, FetchOptions } from '.';
import { timeUtils } from '../helpers/timeUtils';
import utils from '../helpers/utils';
import { StaticProduct } from '../pages/campaigns/builder/cmp.builder.types';
import { DefaultUnlayerTemplate, TemplatesQuery } from '../pages/campaigns/builder/components/template-browser/template.brower.types';
import {
	BulkUpdateItem,
	Campaign,
	CampaignGroup,
	CampaignsContext,
	CampaignTag,
	CampaignTemplatesContext,
	ChannelTemplate,
	DefaultCampaignTemplatesContext,
	EntryMap,
	LanguageType,
	GetTelnyxCampaignOptions,
	TelnyxBrand,
	TelnyxCampaign,
	TelnyxGetBrand,
} from '@/legacy-types';

function getCampaigns(options?: { full?: boolean } & FetchOptions): Promise<Campaign[]> {
	return fetchAPI(`/campaigns/:uid?full=${options?.full || ''}`, { method: 'GET', ...options });
}

function getCampaign(id?: string, options?: FetchOptions): Promise<Campaign> {
	return fetchAPI(`/campaign/:uid/${id}`, { method: 'GET', ...options });
}

function createCampaign(campaign: Campaign, options?: FetchOptions): Promise<Campaign> {
	return fetchAPI(`/campaign/:uid`, { method: 'POST', payload: campaign, ...options });
}

function updateCampaign(campaign: Campaign, options?: FetchOptions): Promise<Campaign> {
	return fetchAPI(`/campaign/:uid/${campaign.id}`, { method: 'PUT', payload: { campaign }, ...options });
}

function setCampaignTags(campaignID: string, tags: string[], options?: FetchOptions): Promise<string[]> {
	return fetchAPI(`/campaign/tags/:uid/${campaignID}`, { method: 'POST', payload: tags, ...options });
}

// export type BulkCampaignResponse = {
//     Successes: Campaign[],
//     Errors: BulkCampaign[]
// }

export type BulkCampaign = Campaign & {
	Error: string;
};

function bulkUpsertCampaigns(payload: Campaign[], options?: FetchOptions): Promise<BulkCampaign[]> {
	return fetchAPI(`/bulk/campaigns/:uid`, { method: 'POST', payload, ...options });
}

function archiveCampaign(id?: string, status?: boolean, options?: FetchOptions): Promise<void> {
	return fetchAPI(`/campaign/:uid/${id}/${status}`, { method: 'PUT', ...options });
}

function setCampaignStatus(id?: string, status?: boolean, options?: FetchOptions): Promise<void> {
	return fetchAPI(`/campaign/status/:uid/${id}/${status}`, { method: 'PUT', ...options });
}

function getCampaignPreview(body: any, options?: FetchOptions): any {
	return fetchAPI(`/campaigns/preview/:uid`, { method: 'POST', payload: body, ...options });
}

function getCampaignGroups(options?: FetchOptions): Promise<CampaignGroup[]> {
	return fetchAPI(`/campaign/groups/:uid`, { method: 'GET', ...options });
}

function getCampaignGroup(id?: string, options?: FetchOptions): Promise<CampaignGroup> {
	return fetchAPI(`/campaign/groups/:uid/${id}`, { method: 'GET', ...options });
}

function createCampaignGroup(group: CampaignGroup, options?: FetchOptions): Promise<CampaignGroup> {
	return fetchAPI(`/campaign/group/:uid`, { method: 'POST', payload: group, ...options });
}

function updateCampaignGroup(group: CampaignGroup, options?: FetchOptions): Promise<CampaignGroup> {
	return fetchAPI(`/campaign/group/:uid/${group.id}`, { method: 'PUT', payload: group, ...options });
}

function archiveCampaignGroup(group: CampaignGroup, status?: boolean, options?: FetchOptions): Promise<void> {
	return fetchAPI(`/campaign/group/:uid/${group.id}/${status}`, { method: 'PUT', ...options });
}

function getCampaignReports(start: number = -1, end: number = -1, options?: FetchOptions): Promise<any> {
	return fetchAPI(`/campaigns/:uid/${start}/${end}`, { method: 'GET', ...options });
}

function bulkCampaignAction(payload: BulkUpdateItem[], options?: FetchOptions): Promise<void> {
	return fetchAPI(`/campaigns/:uid`, { method: 'PUT', payload, ...options });
}

function getVoiceDropLanguageList(options?: FetchOptions): Promise<{ [key: string]: LanguageType }> {
	return fetchAPI(`/listAvailableVoices/:uid`, { method: 'GET', ...options });
}

function getVoiceDropMacroList(options?: FetchOptions): Promise<string[]> {
	return fetchAPI(`/listAvailableVoiceMacros/:uid`, { method: 'GET', ...options });
}

function sendTestCall(phone: string, campaign: Campaign, options?: FetchOptions): Promise<void> {
	return fetchAPI(`/sendTestCall/:uid/${phone}`, { method: 'POST', payload: campaign, ...options });
}

function getAllCampaignTags(options?: FetchOptions): Promise<CampaignTag[]> {
	return fetchAPI(`/campaign/tags/:uid`, { method: 'GET', ...options });
}

function upsertCampaignTag(payload: CampaignTag, options?: FetchOptions): Promise<CampaignTag> {
	return fetchAPI(`/campaign/tag/:uid`, { method: 'POST', payload, ...options });
}

function updateCampaigTags(campaignID: string, tags: CampaignTag[], options?: FetchOptions): Promise<CampaignTag[]> {
	return fetchAPI(`/campaign/tags/:uid/${campaignID}`, { method: 'POST', payload: tags, ...options });
}

function deleteCampaignTag(tagID: string, options?: FetchOptions): Promise<boolean> {
	return fetchAPI(`/campaign/tag/:uid/${tagID}`, { method: 'DELETE', ...options });
}

type SynthesizeInput = { txt: string; key: string };

function getVoiceDropPreview(payload: SynthesizeInput): Promise<any> {
	return fetchAPI(`/previewTextToSpeech/:uid`, { method: 'POST', payload });
}

function createChannelTemplate(template: ChannelTemplate, options?: FetchOptions): Promise<ChannelTemplate> {
	return fetchAPI(`/channelTemplate/:uid`, { method: 'POST', payload: template, ...options });
}

function updateChannelTemplate(template: ChannelTemplate, options?: FetchOptions): Promise<ChannelTemplate> {
	return fetchAPI(`/channelTemplate/:uid/${template.id}`, { method: 'PUT', payload: template, ...options });
}

function deleteChannelTemplate(templateID: string, options?: FetchOptions): Promise<boolean> {
	return fetchAPI(`/channelTemplate/:uid/${templateID}`, { method: 'DELETE', ...options });
}

function getChannelTemplates(options?: FetchOptions): Promise<ChannelTemplate[]> {
	return fetchAPI(`/channelTemplate/:uid`, { method: 'GET', ...options });
}

function getChannelTemplate(templateID: string, options?: FetchOptions): Promise<ChannelTemplate> {
	return fetchAPI(`/channelTemplate/:uid/${templateID}`, { method: 'GET', ...options });
}

function getDefaultTemplates(
	query: TemplatesQuery,
	options?: FetchOptions,
): Promise<{
	pages: number;
	templates: DefaultUnlayerTemplate[];
}> {
	return fetchAPI(`/unlayer/templates/:uid`, { method: 'POST', payload: query, ...options });
}

type StaticProductFilters = {
	slugs?: string[];
	brands?: string[];
	categories?: string[];
};
function getStaticProducts(payload: StaticProductFilters, options?: FetchOptions): Promise<EntryMap<StaticProduct>> {
	return fetchAPI(`/staticProducts/:uid`, { payload, method: 'POST', ...options });
}

function getEcomBrands(options?: FetchOptions): Promise<string[] | null> {
	return fetchAPI(`/ecomBrands/:uid`, { method: 'GET', ...options });
}

function getEcomCategories(options?: FetchOptions): Promise<string[] | null> {
	return fetchAPI(`/ecomCategories/:uid`, { method: 'GET', ...options });
}

function lockCampaign(id?: string, options?: FetchOptions): Promise<void> {
	return fetchAPI(`/campaign/lock/:uid/${id}`, { method: 'POST', ...options });
}

function getCampaignConversionsReport(campaignID: string, options?: FetchOptions): Promise<string> {
	return fetchAPI(`/conversions/:uid/${campaignID}`, { method: 'GET', ...options });
}

export function createTelnyxBrand(brand: Partial<TelnyxBrand>): Promise<TelnyxBrand> {
	return fetchAPI(`/telnyx/brand/:uid`, { method: 'POST', payload: brand });
}

export function getTelnyxBrand(): Promise<TelnyxGetBrand> {
	return fetchAPI(`/telnyx/brand/:uid`, { method: 'GET', forceToken: utils.TOKEN });
}

export function createTelnyxCampaign(campaign: TelnyxCampaign, options?: FetchOptions): Promise<TelnyxCampaign> {
	return fetchAPI(`/telnyx/campaign/:uid`, { method: 'POST', payload: campaign, ...options });
}

export function getTelnyxCampaign(): Promise<GetTelnyxCampaignOptions> {
	return fetchAPI(`/telnyx/campaign/:uid`, { method: 'GET' });
}

type GetAllCampaignOptions = {
	full?: boolean;
	fullStart?: number;
	fullEnd?: number;
	fullLimit?: number;
};

type CampaignArgs = CampaignsContext;
// Get all campaigns and store them in the campaign context, so we can avoid fetching it multiple times per refresh
async function getCampaignsMemoized(options?: GetAllCampaignOptions, ...[campaignData, setCampaignData]: CampaignArgs) {
	const isSet = campaignData.campaigns && Object.keys(campaignData.campaigns || {}).length > 0;
	const needsUpdate = new Date().getTime() - (campaignData.updated || 0) > timeUtils.units.MINUTE_MS * 10; // Campaigns are updated every 10 minutes or per refresh
	// Check that number of entities matches the number of campaigns
	// We have already fetched the data, and it's not too old
	if (isSet && !needsUpdate) return Object.values(campaignData.campaigns || {});
	// Fetch data , update context , and return
	const campaignsList = await getCampaigns({ full: options?.full });
	const campaigns = campaignsList.reduce((acc: any, campaign: any) => ({ ...acc, [campaign.id]: campaign }), {});
	if (setCampaignData) setCampaignData({ ...campaignData, campaigns, updated: new Date().getTime() });
	return campaignsList;
}

// Get a single campaign and store it in the campaign context, so we can avoid fetching it multiple times per refresh
async function getCampaignMemoized(campaignID: string, options?: { forceUpdate?: boolean }, ...[campaignData, setCampaignData]: CampaignArgs) {
	const isSet = campaignData.campaigns && Object.keys(campaignData.campaigns || {}).length > 0;
	// Check the campaign is in the map of campaigns
	const cachedCampaign = (campaignData.campaigns || {})[campaignID];
	// If it exists, we check if it's too old
	const needsUpdate = cachedCampaign && new Date().getTime() - (cachedCampaign.contextUpdated || 0) > timeUtils.units.MINUTE_MS * 1; // Campaign context is updated every minute or per refresh
	// We have already fetched the data, and it's not too old
	if (isSet && !needsUpdate && cachedCampaign && !options?.forceUpdate) return cachedCampaign;
	// Fetch data , update context , and return
	const campaign = await getCampaign(campaignID);
	// Update the campaign context, adding the update time, and then return the campaign
	const campaigns = { ...campaignData.campaigns, [campaignID]: { ...campaign, contextUpdated: new Date().getTime() } };
	if (setCampaignData) setCampaignData({ ...campaignData, campaigns, updated: new Date().getTime() });
	return campaign;
}

// memoixed version of getChannelTemplates.. used for inital load of templates
async function getChannelTemplatesMemoized(options?: FetchOptions, ...[templates, setTemplates]: CampaignTemplatesContext): Promise<ChannelTemplate[]> {
	const isSet = templates && Object.keys(templates?.templates || {}).length > 0;
	const needsUpdate = new Date().getTime() - (templates?.updated || 0) > timeUtils.units.MINUTE_MS * 10; // Templates are updated every 10 minutes or per refresh
	// Check that number of entities matches the number of templates
	// We have already fetched the data, and it's not too old
	if (isSet && !needsUpdate) return Object.values(templates?.templates || {});
	// Fetch data , update context , and return
	const templatesList = (await getChannelTemplates(options)) || [];
	const templatesMap = templatesList.reduce((acc: any, template: ChannelTemplate) => ({ ...acc, [template.id]: template }), {});
	if (setTemplates) setTemplates({ templates: templatesMap, updated: new Date().getTime() });
	return templatesList;
}

// memoized version of getDefaulTemplates.. used for inital load of templates
async function getDefaultTemplatesMemoized(
	query: TemplatesQuery,
	options?: FetchOptions,
	...[templates, setTemplates]: DefaultCampaignTemplatesContext
): Promise<DefaultUnlayerTemplate[]> {
	const isSet = templates && Object.keys(templates.templates || {}).length > 0;
	const needsUpdate = new Date().getTime() - (templates?.updated || 0) > timeUtils.units.MINUTE_MS * 10; // Templates are updated every 10 minutes or per refresh
	// Check that number of entities matches the number of templates
	// We have already fetched the data, and it's not too old
	if (isSet && !needsUpdate) return Object.values(templates.templates || {});
	// Fetch data , update context , and return
	const templatesList = (await getDefaultTemplates(query, options)).templates || [];
	const templatesMap = templatesList.reduce((acc: any, template: DefaultUnlayerTemplate) => ({ ...acc, [template.id]: template }), {});
	if (setTemplates) setTemplates({ templates: templatesMap, updated: new Date().getTime() });
	return templatesList;
}

export const CampaignsAPI = {
	setCampaignTags,
	getCampaigns,
	getCampaign,
	createCampaign,
	updateCampaign,
	archiveCampaign,
	setCampaignStatus,
	getCampaignPreview,
	getCampaignGroups,
	getCampaignGroup,
	createCampaignGroup,
	updateCampaignGroup,
	archiveCampaignGroup,
	getCampaignReports,
	bulkCampaignAction,
	getCampaignsMemoized,
	getCampaignMemoized,
	getVoiceDropLanguageList,
	sendTestCall,
	getVoiceDropPreview,
	getVoiceDropMacroList,
	getAllCampaignTags,
	upsertCampaignTag,
	deleteCampaignTag,
	bulkUpsertCampaigns,
	createChannelTemplate,
	updateChannelTemplate,
	deleteChannelTemplate,
	getChannelTemplates,
	getChannelTemplate,
	getDefaultTemplates,
	getChannelTemplatesMemoized,
	getDefaultTemplatesMemoized,
	getStaticProducts,
	getEcomBrands,
	getEcomCategories,
	updateCampaigTags,
	lockCampaign,
	getAllCampaignConversions: getCampaignConversionsReport,
	createTelnyxBrand,
	getTelnyxBrand,
	createTelnyxCampaign,
	getTelnyxCampaign,
};
