import { FetchOptions, fetchAPI } from '.';
import { Audience, Campaign, Discount, Recipe, RecipeGroup, RecipeInfo, TemplateSummary } from '@/legacy-types';

function getAllTemplates(): Promise<Recipe[]> {
	return fetchAPI(`/templates/all/:uid`, { method: 'GET' });
}

function getAllTemplatesForUser(options?: { skipStats?: boolean; active?: boolean }, opts?: FetchOptions): Promise<Recipe[]> {
	return fetchAPI(`/templates/user/:uid?skipStats=${!!options?.skipStats}&active=${!!options?.active}`, { method: 'GET', ...opts });
}

function getTemplate(id: string): Promise<Recipe> {
	return fetchAPI(`/templates/template/:uid?recipeID=${id}`, { method: 'GET' });
}

function createTemplate(template: Recipe): Promise<Recipe> {
	return fetchAPI(`/templates/template/create/:uid`, { method: 'POST', payload: template });
}

function updateTemplate(template: Recipe): Promise<Recipe> {
	return fetchAPI(`/templates/template/update/:uid?recipeID=${template?.id}`, { method: 'PUT', payload: template });
}

export type RecipeArchiveConfig = {
	id: string;
	status: boolean;
	ignoreGroups?: boolean;
	ignoreAssets?: boolean;
};

function archiveTemplate(payload: RecipeArchiveConfig): Promise<void> {
	return fetchAPI(`/templates/template/archived/:uid`, { method: 'PUT', payload });
}

function setTemplateStatus(id: string, status: boolean): Promise<void> {
	return fetchAPI(`/templates/template/status/:uid?recipeID=${id}/${status}`, { method: 'PUT' });
}

export type SetRecipeApprovedInput = {
	sponsorID: string;
	retailerID: string;
	recipeID: string;
	approved: boolean;
	noNotification: boolean;
};

function setTemplateApproved(payload: SetRecipeApprovedInput): Promise<void> {
	return fetchAPI(`/templates/template/changes/status/:uid`, { method: 'PUT', payload });
}

export type RecipeAssetsInput = {
	uid: string;
	templateID: string;
};

export type TemplateAssets = {
	id: string;
	recipe: Recipe;
	audiences: Audience[];
	campaigns: Campaign[];
	discounts: Discount[];
};

function getTemplateAssets(payload: RecipeAssetsInput): Promise<TemplateAssets> {
	return fetchAPI(`/templates/template/assets/:uid`, { method: 'PUT', payload });
}

function addTemplateView(id: string): Promise<void> {
	return fetchAPI(`/templates/template/view/:uid?recipeID=${id}`, { method: 'PUT' });
}

export type InstallRecipeConfig = {
	sponsorID: string;
	recipeID: string;
	installerID: string;
	activate: boolean;
	allowOverrun: boolean;
};

function installTemplate(payload: InstallRecipeConfig): Promise<Recipe> {
	return fetchAPI(`/templates/template/install/:uid`, { method: 'POST', payload });
}

export type ApplyTemplateChangesConfig = {
	reason?: string;
	recipe?: RecipeInfo;
	audiences?: Audience[];
	campaigns?: Campaign[];
	discounts?: Discount[];
	newAudiences?: Audience[];
	newCampaigns?: Campaign[];
	newDiscounts?: Discount[];
};

function applyTemplateChanges(payload: ApplyTemplateChangesConfig): Promise<void> {
	return fetchAPI(`/templates/template/changes/apply/:uid`, { method: 'POST', payload });
}

export type UpdateChangesInput = {
	brandID?: string;
	brandRID?: string;
	retailerID?: string;
	retailerRID?: string;
	type?: string;
	audiences?: Audience[];
	campaigns?: Campaign[];
	discounts?: Discount[];
	clearChanges?: boolean;
	clearAllChanges?: boolean;
	stayApproved?: boolean;
};

function updateTemplateChanges(payload: UpdateChangesInput): Promise<void> {
	return fetchAPI(`/templates/template/changes/update/:uid`, { method: 'PUT', payload });
}

export type RecommendedAdditionsInput = {
	recipe: Recipe;
	audiences: string[];
	campaigns: string[];
	discounts: string[];
	clearChanges: boolean;
};

function addTemplateChangesAssets(payload: RecommendedAdditionsInput): Promise<void> {
	return fetchAPI(`/templates/template/changes/addAssets/:uid`, { method: 'PUT', payload });
}

function getTemplateChangeAssets(id?: string): Promise<TemplateAssets> {
	return fetchAPI(`/templates/template/changes/getAssets/:uid?recipeID=${id}`, { method: 'PUT' });
}

function getTemplateSummary(id?: string): Promise<TemplateSummary> {
	return fetchAPI(`/templates/template/summary/:uid?recipeID=${id}`, { method: 'GET' });
}

function setTemplateGroupArchived(groupID: string, status: boolean): Promise<void> {
	return fetchAPI(`/templates/group/archived/:uid?gid=${groupID}&status=${status}`, { method: 'PUT' });
}

function getTemplateGroups(): Promise<RecipeGroup[]> {
	return fetchAPI(`/templates/groups/all/:uid`, { method: 'GET' });
}

function getTemplateGroup(groupID: string): Promise<RecipeGroup> {
	return fetchAPI(`/templates/group/:uid?gid=${groupID}`, { method: 'GET' });
}

function createTemplateGroup(group: RecipeGroup): Promise<RecipeGroup> {
	return fetchAPI(`/templates/groups/create/:uid`, { method: 'POST', payload: group });
}

// function updateTemplateGroup(group: RecipeGroup): Promise<RecipeGroup> {
//     return fetchAPI(`/templates/groups/update/:uid?gid=${group.id}`, { method: 'PUT', payload: group })
// }

export const RecipesAPI = {
	getAllTemplates,
	getAllTemplatesForUser,
	getTemplate,
	createTemplate,
	updateTemplate,
	archiveTemplate,
	setTemplateStatus,
	setTemplateApproved,
	getTemplateAssets,
	addTemplateView,
	installTemplate,
	applyTemplateChanges,
	updateTemplateChanges,
	addTemplateChangesAssets,
	getTemplateChangeAssets,
	getTemplateSummary,
	setTemplateGroupArchived,
	getTemplateGroups,
	getTemplateGroup,
	createTemplateGroup,
	// updateTemplateGroup,
};
