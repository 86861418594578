import { fetchAPI, FetchOptions } from '.';
import { utils } from '../helpers';
import { Conversation, SendChatReq } from '@/legacy-types';

export function getChatConversations(options?: FetchOptions): Promise<Conversation[]> {
	return fetchAPI('/inbox/:uid', { method: 'GET', ...options });
}

export type ConvoFilters = {
	lookbackDays?: number;
	ignoreInbox?: string[];
};

export function getChatConversationsV2(payload: ConvoFilters, options?: FetchOptions): Promise<{ [key: string]: Conversation }> {
	return fetchAPI('/inbox/v2/:uid', { method: 'POST', payload, ...options });
}

export function getChatConversation(number: string, payload?: ConvoFilters, options?: FetchOptions): Promise<{ [key: string]: Conversation }> {
	return fetchAPI(`/inbox/v2/:uid?number=${number}`, { method: 'POST', payload, ...options });
}

export function sendChatResponse(payload: SendChatReq, storeID?: string, options?: FetchOptions): Promise<Conversation> {
	return fetchAPI(`/inbox/send/:uid?storeID=${storeID}`, { method: 'POST', ...options, payload });
}

export function getCannedResponses(): { [key: string]: string } {
	return (
		(utils.user.cndResp as Record<string, string>) ?? {
			'1': 'Hello! How can I help you today?',
			'2': 'I will be with you in a moment.',
			'3': 'Thank you for contacting us. How can I help you today?',
			'4': 'Thank you for your patience. I will be with you shortly.',
		}
	);
}

export function updateCannedResponses(payload: { [key: string]: string }, options?: FetchOptions): Promise<void> {
	return fetchAPI(`/users/canned/:uid`, { method: 'PUT', ...options, payload });
}
