import { fetchAPI } from "..";
import { ReportInfo } from "../../pages/analytics/insights/reports/ScheduledReports";


function getScheduledReports(): Promise<ReportInfo[]> {
    return fetchAPI(`/scheduled/:uid`, { method: 'GET' })
}

function getScheduledReport(id?: string): Promise<ReportInfo> {
    return fetchAPI(`/scheduled/:uid/${id}`, { method: 'GET' })
}

function createScheduledReport(report: ReportInfo): Promise<ReportInfo> {
    return fetchAPI(`/scheduled/:uid`, { method: 'POST', payload: report })
}

function updateScheduledReport(report: ReportInfo): Promise<ReportInfo> {
    return fetchAPI(`/scheduled/:uid/${report.id}`, { method: 'PUT', payload: report })
}

function deleteScheduledReport(id?: string): Promise<void> {
    return fetchAPI(`/scheduled/:uid/${id}`, { method: 'DELETE' })
}

export const ScheduledReportsAPI = {
    getScheduledReports,
    getScheduledReport,
    createScheduledReport,
    updateScheduledReport,
    deleteScheduledReport,
}