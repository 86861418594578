import { fetchAPI } from '.';
import { timeUtils } from '../helpers/timeUtils';
import {
	AccountsContext,
	AllowAdsAccount,
	AllStoresContext,
	Login,
	LoginsContext,
	PublicStore,
	PublicStoresContext,
	Store,
	StoreWithChannel,
	ThirdPartyStoresContext,
	UserStoresContext,
} from '@/legacy-types';

// All accounts data is stored in the auth context, so we can avoid fetching it multiple times per refresh
export async function getAllAccounts(options?: {}, ...[context, setContext]: AccountsContext): Promise<AllowAdsAccount[]> {
	const isSet = context.accounts && Object.keys(context.accounts || {}).length > 0;
	const needsUpdate = new Date().getTime() - (context.updated || 0) > timeUtils.units.MINUTE_MS * 10;
	// We have already fetched the data, and it's not too old
	if (isSet && !needsUpdate) return Object.values(context.accounts || {});
	// Fetch data , update context , and return
	const accountsL = await fetchAPI(`/allAllowAds/:uid`, { method: 'GET' });
	// const accounts = await fetchAPI(`https://lab.alpineiq.com/api/v1/allAllowAds/1852`, { ...options, method: 'GET' })
	const accounts = (accountsL || []).reduce((acc: any, account: any) => ({ ...acc, [account.uid]: account }), {});
	if (setContext) setContext({ ...context, accounts, updated: new Date().getTime() });
	return accountsL;
}

// All stores data is stored in the auth context, so we can avoid fetching it multiple times per refresh
export async function getAllStores(options?: {}, ...[context, setContext]: AllStoresContext): Promise<Store[]> {
	const isSet = context.stores && Object.keys(context.stores || {}).length > 0;
	const needsUpdate = new Date().getTime() - (context.updated || 0) > timeUtils.units.MINUTE_MS * 10;
	// We have already fetched the data, and it's not too old
	if (isSet && !needsUpdate) return Object.values(context.stores || {});
	// Fetch data , update context , and return
	const storeL: Store[] = (await fetchAPI(`/allStores/:uid`, { method: 'GET' })) || [];
	// const stores: Store[] = (await fetchAPI(`https://lab.alpineiq.com/api/v1/allStores/1852`, { ...options, method: 'GET' }) || [])
	const stores = (storeL || []).reduce((acc: any, store: Store) => ({ ...acc, [store.id || '']: store }), {});
	if (setContext) setContext({ ...context, stores, updated: new Date().getTime() });
	return storeL;
}

// All third party stores data is stored in the auth context, so we can avoid fetching it multiple times per refresh
export async function getThirdPartyStores(
	options?: { getChannels?: boolean; forceToken?: string },
	...[context, setContext]: ThirdPartyStoresContext
): Promise<StoreWithChannel[]> {
	const isSet = context.stores && Object.keys(context.stores || {}).length > 0;
	const needsUpdate = new Date().getTime() - (context.updated || 0) > timeUtils.units.MINUTE_MS * 10;
	// We have already fetched the data, and it's not too old
	if (isSet && !needsUpdate) return Object.values(context.stores || {});
	// Fetch data , update context , and return
	const storesL = await fetchAPI(`/thirdPartyStores/:uid?getChannels=${options?.getChannels}`, { method: 'GET' });
	// const stores = await fetchAPI(`https://lab.alpineiq.com/api/v1/thirdPartyStores/1852?getChannels=${options?.getChannels}`, { ...options, method: 'GET' })
	const stores = (storesL || []).reduce((acc: any, store: StoreWithChannel) => ({ ...acc, [store.id || '']: store }), {});
	if (setContext) setContext({ ...context, stores, updated: new Date().getTime() });
	return storesL;
}

// Get public store data
export async function getPubilcStores(options?: {}, ...[context, setContext]: PublicStoresContext): Promise<PublicStore[]> {
	const isSet = context.stores && Object.keys(context.stores || {}).length > 0;
	const needsUpdate = new Date().getTime() - (context.updated || 0) > timeUtils.units.MINUTE_MS * 10;
	// We have already fetched the data, and it's not too old
	if (isSet && !needsUpdate) return Object.values(context.stores || {}) as PublicStore[];
	// Fetch data , update context , and return
	const storesL = await fetchAPI(`/stores/public/:uid`, { method: 'GET' });
	// const stores = await fetchAPI(`https://lab.alpineiq.com/api/v1/stores/public/1852`, { ...options, method: 'GET' })
	const stores = (storesL || []).reduce((acc: any, store: PublicStore) => ({ ...acc, [store.id]: store }), {});
	if (setContext) setContext({ ...context, stores, updated: new Date().getTime() });
	return storesL;
}

// Get public store data
export async function getUserStores(options?: {}, ...[context, setContext]: UserStoresContext): Promise<Store[]> {
	const isSet = context.stores && Object.keys(context.stores || {}).length > 0;
	const needsUpdate = new Date().getTime() - (context.updated || 0) > timeUtils.units.MINUTE_MS * 10;
	// We have already fetched the data, and it's not too old
	if (isSet && !needsUpdate) return Object.values(context.stores || {}) as Store[];
	// Fetch data , update context , and return
	const response = await fetchAPI(`/stores/:uid/-1`, { method: 'GET' });
	// const { stores } = await fetchAPI(`https://lab.alpineiq.com/api/v1/stores/1852/-1`, { ...options, method: 'GET' })
	const stores = (response.stores || []).filter((x: Store) => !x.isBrandStore).reduce((acc: any, store: Store) => ({ ...acc, [store.id || '']: store }), {});
	if (setContext) setContext({ ...context, stores, updated: new Date().getTime() });
	return response.stores;
}

export async function getUserLogins(options?: {}, ...[context, setContext]: LoginsContext): Promise<Login[]> {
	const isSet = context.logins && Object.keys(context.logins || {}).length > 0;
	const needsUpdate = new Date().getTime() - (context.updated || 0) > timeUtils.units.MINUTE_MS * 10;
	// We have already fetched the data, and it's not too old
	if (isSet && !needsUpdate) return Object.values(context.logins || {}) as Login[];
	// Fetch data , update context , and return
	const response = (await fetchAPI(`/users/sub/:uid`, { method: 'GET' })) || [];
	const logins = (response || []).reduce((acc: any, login: Login) => ({ ...acc, [login.email]: login }), {});
	if (setContext) setContext({ ...context, logins, updated: new Date().getTime() });
	return response;
}

export const GeneralAPI = {
	getAllAccounts,
	getAllStores,
	getThirdPartyStores,
	getPubilcStores,
	getUserStores,
	getUserLogins,
};
