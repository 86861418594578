import { FetchOptions, fetchAPI } from "."


export type CollectibleBatch = {
    created: string
    id: string
    prefix: string
    quantity: number
    points: number
    active: boolean
    used?: number
}

export type GenerateCollectibleCodesReq = {
    user_id: string
    prefix: string
    quantity: number
    points: number
}

export type CollectibleCode = {
    code: string
    active: boolean
    used: boolean
}

export type CodeForCSV = CollectibleCode & {
    batch_id: string
    points: number
    created: string
}

export type CollectibleUsage = {
    remaining: number // Total amount of codes remaining
    batches: UsageBatch[] // Usage per batch
}

export type UsageBatch = {
    id: string// Batch ID
    total: number// Amount of codes in the batch
    used: number// Amount of codes used
    created: string// Time the batch was created
}

export type RedeemCollectibleCodeReq = {
    contactID: string
    code: string
    phone: string
    email: string
}

// marketing.GET("/collectible/batches/:uid", s.getCollectibleCodeBatches)
// marketing.GET("/collectible/batch/:uid/:batchID", s.getCollectibleCodeBatch)
// marketing.GET("/collectible/batch/codes/:uid/:batchID", s.getCollectibleCodesForBatch)
// marketing.GET("/collectible/usage/:uid", s.getCollectibleUsageForUser)
// marketing.POST("/collectible/generate/:uid", s.generateColletibleCodeBatch)
// marketing.PUT("/collectible/batch/:uid/:batchID/:status", s.setCollectibleCodeBatchStatus)
// collectible.POST("/collectible/redeem/:uid", s.redeemCollectibleCode)

export function getAllCollectibleBatches(options?: FetchOptions): Promise<CollectibleBatch[]> {
    return fetchAPI('/collectible/batches/:uid', { ...options, method: 'GET' })
}

export function getCollectibleCodeBatch(batchID?: string, options?: FetchOptions): Promise<CollectibleBatch> {
    return fetchAPI(`/collectible/batch/:uid/${batchID}`, { ...options, method: 'GET' })
}

export function getCollectibleCodesForBatch(batchID?: string, options?: FetchOptions): Promise<CollectibleCode[]> {
    return fetchAPI(`/collectible/batch/codes/:uid/${batchID}`, { ...options, method: 'GET' })
}

export function getCollectibleUsageForUser(options?: FetchOptions): Promise<CollectibleUsage> {
    return fetchAPI('/collectible/usage/:uid', { ...options, method: 'GET' })
}

export function generateColletibleCodeBatch(data: GenerateCollectibleCodesReq, options?: FetchOptions): Promise<CollectibleBatch> {
    return fetchAPI('/collectible/generate/:uid', { ...options, method: 'POST', payload: data })
}

export function setCollectibleCodeBatchStatus(batchID: string, status: boolean, options?: FetchOptions): Promise<CollectibleBatch> {
    return fetchAPI(`/collectible/batch/:uid/${batchID}/${status}`, { ...options, method: 'PUT' })
}

export function redeemCollectibleCode(payload: RedeemCollectibleCodeReq, options?: FetchOptions): Promise<boolean> {
    return fetchAPI('/collectible/redeem/:uid', { ...options, method: 'POST', payload })
}